
import { defineComponent, ref } from 'vue'
import { getUpateRecordDetail } from '@/API/vl/vlOverview'
const tableHeight = window.innerHeight - 300

export default defineComponent({
  setup() {
    const columns = [
      { title: '操作角色', width: '15%', dataIndex: 'opRole', key: 'opRole' },
      { title: '原值', width: '35%', dataIndex: 'opOldValue', key: 'opOldValue' },
      { title: '更新值', width: '35%', dataIndex: 'opNewValue', key: 'opNewValue' },
      { title: '操作时间', width: '15%', dataIndex: 'opTime', key: 'opTime' }]
    const current = ref<number>(1)
    const size = ref<number>(20)
    const data = ref([])
    const total = ref(0)
    const initData = () => {
      const params = {
        vinNo: sessionStorage.getItem('editVlLincese'),
        dealerCode: sessionStorage.getItem('editVlDealerCode'),
        current: current.value,
        size: size.value,
      }
      getUpateRecordDetail({ params }).then((res): any => {
        data.value = res.content
        total.value = res.totalElements
      })
    }
    initData()
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1
      size.value = pageSize
      initData()
    }
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1
      size.value = pageSize
      initData()
    }
    return {
      columns,
      data,
      current,
      size,
      total,
      pageChange,
      sizeChange,
      tableHeight
    }
  },
})
