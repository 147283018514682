
import { defineComponent, reactive, ref, nextTick } from 'vue';
import { getCheckingSearchResult } from '@/API/vl/vlSICCOChecking';
import { CheckingResultSearchType, CheckingResultSearchListType, SelectArrayType } from '@/views/VehicleLicense/types';
import setColumnWidth from '@/utils/rv/setColumnWidth';
import downloadFile from '@/utils/claim/downloadFile';
import CheckingDetail from '@/views/VehicleLicense/CheckingResult/components/checkingDetail.vue';
import { useStore } from 'vuex';
import AppealInfo from '@/views/VehicleLicense/CheckingResult/components/appealInfo.vue';
import AppealInfoViewIndex from '@/views/VehicleLicense/CheckingResult/components/index.vue';
// import NoticeModal from "@/components/NoticeModal.vue";
import { message } from 'ant-design-vue';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';
const columns = [
  { title: '车架号', dataIndex: 'vinNo', width: 168 },
  { title: '提交状态', dataIndex: 'licenseSubmitStatus', width: 80 },
  { title: '车牌号码', dataIndex: 'licenseNumber', width: 100 },
  { title: '注册日期', dataIndex: 'regDate', width: 130 },
  { title: '提交日期', dataIndex: 'submissionDate', width: 130 },
  { title: '批发来源', dataIndex: 'entity', width: 100 },
  { title: '品牌/车型', dataIndex: 'brand', width: 90 },
  { title: '客户名称', dataIndex: 'customerName', width: 100 },
  { title: '零售类型', dataIndex: 'usage', width: 104 },
  { title: '零售日期', dataIndex: 'retailDate', width: 130 },
  { title: '申诉截止期', dataIndex: 'effectiveAppealDate', width: 130 },
  {
    title: '核查结果',
    fixed: 'right',
    width: 120,
    dataIndex: 'checkResult',
    slots: { customRender: 'checkResult' }
  },
  {
    title: '操作',
    fixed: 'right',
    width: 90,
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
];
const checkingResultArray = ['全部', '待申诉', '审核通过', '审核未通过', '申诉核查中'];
const tableWidth = window.innerWidth;
export default defineComponent({
  components: { CheckingDetail, AppealInfo, AppealInfoViewIndex },
  setup() {
    const appealInfo = ref(true);
    const appealInfoView = ref(true);
    // 表单验证
    const formRef = ref();
    const rules = {
      retailDate: [
        {
          validator: (rule: any, value: any, cbfn: any) => {
            if (value.length > 1) {
              return Promise.resolve();
            } else {
              return Promise.reject('不可以为空');
            }
          }
        }
      ],
      bu: [
        {
          validator: (rule: any, value: any, cbfn: any) => {
            if (value) {
              return Promise.resolve();
            } else {
              return Promise.reject('不可以为空');
            }
          }
        }
      ]
    };
    const vinNo = ref('');
    const vinNos = ref<string[]>([]);
    // 批量复制
    const batchQuery = reactive({
      batchQueryTitle: '',
      batchQueryModal: false,
      batchQueryListTitle: ''
    });
    const textContentNo = ref<string>('');
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(['', '', '', '', '', '']);

    const close = () => {
      isOpen.value = false;
    };

    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      textNoArray.value = cleanEmptyEleArray(vinNos.value)[0] === '' ? [] : cleanEmptyEleArray(vinNos.value);
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = '车架号批量查询';
      batchQuery.batchQueryListTitle = '车架号列表';
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      vinNo.value = vinNos.value[0];
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('请从Excel中粘贴到文本框');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ',')
          .split(',')
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      vinNos.value = textNoArray.value;
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      vinNos.value = [];
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };

    const isOpen = ref<boolean>(false);
    const monthDisable = ref<boolean>(true);
    const searchParams = reactive<CheckingResultSearchType>({
      retailDate: [],
      bu: '全部',
      registrationPeriod: [],
      submissionPeriod: [],
      checkResult: '全部'
    });
    const params = reactive<CheckingResultSearchListType>({
      retailStartDate: '',
      retailEndDate: '',
      bu: '全部',
      registrationStartDate: '',
      registrationEndDate: '',
      submissionStartDate: '',
      submissionEndDate: '',
      checkResult: '全部',
      current: 1,
      size: 20,
      vinNo: '',
      vinList: []
    });
    const overview = reactive({
      licenseEffective: '',
      totalSubmitCount: '',
      effectiveSubmitCount: '',
      unCheckedCount: '',
      toAppeal: '',
      totalRetailCount: '',
      effectiveRetailCount: ''
    });
    // 表格展示
    const tableData = ref([]);
    const tableHeight = ref();
    const searchCon = ref();
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0
    });
    // 核查信息页
    const detailVisible = ref<boolean>(false);
    const btnVisible = ref<boolean>(false);
    const checkingParams = ref<object>({});
    const expandClick = ref<number>(0);
    const store = useStore();
    const serviceTypeArray = ref<SelectArrayType[]>([]);

    const getParams = () => {
      params.retailStartDate = '';
      params.retailEndDate = '';
      params.bu = '';
      params.registrationStartDate = '';
      params.registrationEndDate = '';
      params.submissionStartDate = '';
      params.submissionEndDate = '';
      params.checkResult = '';
      params.current = pagination.currentPage;
      params.size = pagination.pageSize;
      if (vinNos.value.length === 0) {
        params.vinNo = vinNo.value;
      }
      params.vinList = vinNos.value;

      if (searchParams.retailDate.length > 0) {
        params.retailStartDate = searchParams.retailDate[0];
        params.retailEndDate = searchParams.retailDate[1];
      }
      if (searchParams.bu) {
        params.bu = searchParams.bu;
      }
      if (searchParams.registrationPeriod.length > 0) {
        params.registrationStartDate = searchParams.registrationPeriod[0];
        params.registrationEndDate = searchParams.registrationPeriod[1];
      }
      if (searchParams.submissionPeriod.length > 0) {
        params.submissionStartDate = searchParams.submissionPeriod[0];
        params.submissionEndDate = searchParams.submissionPeriod[1];
      }
      if (searchParams.checkResult) {
        params.checkResult = searchParams.checkResult;
      }
    };

    const resetEvent = () => {
      expandClick.value = 0;
      searchParams.retailDate = [];
      searchParams.bu = '全部';
      searchParams.registrationPeriod = [];
      searchParams.submissionPeriod = [];
      searchParams.checkResult = '全部';
      pagination.currentPage = 1;
      pagination.pageSize = 20;
      vinNo.value = '';
      vinNos.value = [];
    };

    // 刷新表格高度
    const getTableScroll = () => {
      if (expandClick.value == 1) {
        expandClick.value = 0;
        nextTick(() => {
          //  默认底部分页50 + 边距10
          const extraHeight = 110;
          const tHeader = document.getElementsByClassName('table-box')[0];
          //表格内容距离顶部的距离
          let tHeaderBottom = 0;
          if (tHeader) {
            tHeaderBottom = tHeader.getBoundingClientRect().top;
          }
          //窗体高度-表格内容顶部的高度-表格内容底部的高度
          const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
          tableHeight.value = height;
          if (!(tableData.value && tableData.value.length > 0)) {
            (document.getElementsByClassName('ant-table-placeholder')[0] as any).style.height = `calc(100vh - ${
              tHeaderBottom + 60
            }px)`;
          }
        });
      } else {
        //  默认底部分页50 + 边距10
        const extraHeight = 110;
        const tHeader = document.getElementsByClassName('table-box')[0];
        //表格内容距离顶部的距离
        let tHeaderBottom = 0;
        if (tHeader) {
          tHeaderBottom = tHeader.getBoundingClientRect().top;
        }
        //窗体高度-表格内容顶部的高度-表格内容底部的高度
        const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
        tableHeight.value = height;
        if (!(tableData.value && tableData.value.length > 0)) {
          (document.getElementsByClassName('ant-table-placeholder')[0] as any).style.height = `calc(100vh - ${
            tHeaderBottom + 60
          }px)`;
        }
      }
    };

    const getTableData = () => {
      formRef.value
        .validate()
        .then(() => {
          isOpen.value = false;
          getParams();
          getCheckingSearchResult(params).then((res: any): void => {
            tableData.value = res.dealerQueryResultRespVOS.content;
            pagination.total = res.dealerQueryResultRespVOS.totalElements;
            overview.licenseEffective = res.dealerOverviewCountRespVo.licenseEffective;
            overview.totalSubmitCount = res.dealerOverviewCountRespVo.totalSubmitCount;
            overview.effectiveSubmitCount = res.dealerOverviewCountRespVo.effectiveSubmitCount;
            overview.unCheckedCount = res.dealerOverviewCountRespVo.unCheckedCount;
            overview.toAppeal = res.dealerOverviewCountRespVo.toAppeal;
            overview.totalRetailCount = res.dealerOverviewCountRespVo.totalRetailCount;
            overview.effectiveRetailCount = res.dealerOverviewCountRespVo.effectiveRetailCount;
            const customerColumn = ['dealerName', 'customer'];
            tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn));
            getTableScroll();
          });
        })
        .catch((error: Event) => {
          getTableScroll();
          console.log('error', error);
        });
    };

    const searchEvent = () => {
      pagination.currentPage = 1;
      getTableData();
    };
    // searchEvent();
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };

    const openDetail = (record: any) => {
      detailVisible.value = true;
      if (record.checkResult == '待申诉' || record.checkResult == '申诉已提交') {
        btnVisible.value = true;
        sessionStorage.setItem('appealvinNo', record.vinNo);
        sessionStorage.setItem('appealDealerCode', record.dealerCode);
      } else {
        btnVisible.value = false;
      }
      checkingParams.value = record.siccoCheckInfoRespVO;
    };
    const openDetailView = (record: any) => {
      sessionStorage.setItem('appealvinNo', record.vinNo);
      sessionStorage.setItem('appealDealerCode', record.dealerCode);
      checkingParams.value = record.siccoCheckInfoRespVO;
      appealInfoView.value = false;
    };

    const closeChecking = () => {
      detailVisible.value = false;
    };

    const closeCheckingResult = () => {
      detailVisible.value = false;
      appealInfo.value = false;
    };

    const exportSearchRes = () => {
      formRef.value
        .validate()
        .then(() => {
          isOpen.value = false;
          getParams();
          const downparams = {
            url: './rvapi/vl/dealer/criteriaQueryExport',
            method: 'post',
            params: params,
            fileName: `${+new Date()}.xlsx`
          };
          downloadFile(downparams, 'application/vnd-excel; char-set=UTF-8');
        })
        .catch((error: Event) => {
          getTableScroll();
          console.log('error', error);
        });
    };

    const closeModal = () => {
      appealInfo.value = true;
      appealInfoView.value = true;
      nextTick(() => {
        searchEvent();
      });
    };

    const init = () => {
      const data = ref<SelectArrayType[]>([]);
      const buList = store.state.user.buList;
      buList.forEach((item: any) => {
        data.value.push({ text: item.nameCn, value: item.id });
      });
      serviceTypeArray.value = data.value;
      searchParams.bu = data.value[0].value;
    };
    init();

    nextTick(() => {
      getTableScroll();
    });

    return {
      appealInfo,
      formRef,
      rules,
      tableWidth,
      columns,
      serviceTypeArray,
      checkingResultArray,
      isOpen,
      monthDisable,
      searchParams,
      params,
      overview,
      detailVisible,
      btnVisible,
      checkingParams,
      tableData,
      tableHeight,
      searchCon,
      getParams,
      getTableScroll,
      getTableData,
      resetEvent,
      searchEvent,
      pagination,
      pageChange,
      sizeChange,
      openDetail,
      openDetailView,
      closeChecking,
      exportSearchRes,
      expandClick,
      store,
      closeCheckingResult,
      closeModal,
      appealInfoView,
      batchQuery,
      textContentNo,
      textNoArray,
      input,
      batchPlaceholder,
      setBatchQueryTextNoArray,
      setBatchPlaceholder,
      batchQueryEvent,
      closeBatchQueryModal,
      textChangEvent,
      clearBatchQuery,
      vinNo,
      vinNos
    };
  }
});
