
import BasicInfo from './appealInfoView.vue';
import OperInfo from './OperInfo.vue';
import UpdateInfo from './UpdateInfo.vue';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    components: { BasicInfo, OperInfo, UpdateInfo },
     props: {
    
    checkingParams: {
      default() {
        return {};
      },
      type: Object,
    },
  },
    setup(props,{ emit }) {
        const activeKey = ref('1');
        const loginShow = () => {
             emit("close-appeal-page-modal");
        };
        const licenseDetailShow = ref('basic');
        const tabchange = (event: string) => {
            switch (event) {
                case '2':
                    licenseDetailShow.value = 'oper';
                    break;
                case '3':
                    licenseDetailShow.value = 'update';
                    break;
                default:
                    licenseDetailShow.value = 'basic';
            }
        };
        return {
            loginShow,
            activeKey,
            tabchange,
            licenseDetailShow,
        };
    },
    onBeforeUnmount() {
        sessionStorage.clear();
    },
});
