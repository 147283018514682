
import base64toBlob from "@/utils/rv/base64toBlob";
import { defineComponent, ref, reactive } from "vue";
import {
  UploadOutlined,
  DeleteOutlined,

  PaperClipOutlined,
} from "@ant-design/icons-vue";
import {
  getLicenseInfo,
  getLicensePicture,
  saveLicenseInfo,
  uploadLicenseFile,
  sumbitLicenseInfo,
  uploadAppealItem,
  deleteAppealItem,
} from "@/API/vl/vlSICCOChecking";
import { FileItem } from "@/API/types";
import NoticeModal from "@/components/NoticeModal.vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    UploadOutlined,
    DeleteOutlined,

    NoticeModal,
    PaperClipOutlined,
  },
  exits: ["close-appeal-page-modal"],
    props: {
    
    checkingParams: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  setup(props, { emit }) {
   
    const data = reactive({
      appealComment: "",
      customerName: "",
      engineNo: "",
      issueDate: "",
      licenseNumber: "",
      regDate: "",
      useNatue: "",
      vehicleType: "",
      vinNo: "",
      dealerCode: sessionStorage.getItem("appealDealerCode") || "",
    });
    const appealComment = ref("");
    const deleteClick = ref(false);
    //弹窗显示
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    //行驶证图片
    const pictureModal = reactive({
      pictureModalImg: "",
      pictureModalTitle: "",
      pictureModalImgNew:'',
      pictureModalTitleNew:'',
    });

    //上传行驶证
    const modalVisible = ref(false);
    const modalData = reactive({});
    const ssFileList = ref<FileItem[]>([]);
    const tempLicenseData = ref();
    const tempAppealData = ref();

    //规则校验
    const formRef = ref();
    const rules = {
      licenseNumber: {
        required: true,
        message: "车牌号码为必填项",
        trigger: "change",
      },
      customerName: {
        required: true,
        message: "客户名称为必填项",
        trigger: "change",
      },
      engineNo: {
        required: true,
        message: "发动机号码为必填项",
        trigger: "change",
      },
      regDate: {
        required: true,
        message: "注册日期为必填项",
        trigger: "change",
      },
      useNatue: {
        required: true,
        message: "使用性质为必填项",
        trigger: "change",
      },
      vehicleType: {
        required: true,
        message: "车辆类型为必填项",
        trigger: "change",
      },
      issueDate: {
        required: true,
        message: "发证日期为必填项",
        trigger: "change",
      },
    };

    const computeSize = (fileSize: string) => {
      const sizeNum = parseFloat(fileSize) / 1024 / 1024;
      const sizeKb = parseFloat(fileSize) / 1024;
      if (sizeNum < 1) {
        return sizeKb.toFixed(2) + "KB";
      } else {
        return sizeNum.toFixed(2) + "MB";
      }
    };

    const computeName = (fileName: string) => {
      if (fileName.length < 15) {
        return fileName;
      } else {
        return fileName.slice(0, 15).concat(".....");
      }
    };

    const getLicenseImg = () => {
      deleteClick.value = false;
      const params = {
        vinNo: sessionStorage.getItem("appealvinNo"),
        dealerCode: sessionStorage.getItem("appealDealerCode"),
        fileName: "RE_" + sessionStorage.getItem("appealvinNo"),
      };
      getLicensePicture(params).then((res: any): void => {
        pictureModal.pictureModalImg = res.downloadDetail.downloadUrl;
        pictureModal.pictureModalTitle = "RE_" + params.vinNo + ".jpg";
        data["licensePath"] = res.licensePath;
        pictureModal.pictureModalImgNew = res.downloadAppealDetail.downloadUrl;
        pictureModal.pictureModalTitleNew = "RE_" + params.vinNo + ".jpg";
        data["appealLicensePath"] = res.appealLicensePath;
      });
    };

    //获取行驶证信息
    const getLicenseItem = () => {
      const vinNo: any = sessionStorage.getItem("appealvinNo");
      const dealerCode: string =
        sessionStorage.getItem("appealDealerCode") || "";
      getLicenseInfo(vinNo, dealerCode).then((res: any) => {
        Object.assign(data, res.data);
        ssFileList.value = res.data.appealFileInfoList.map((item: any) => {
          return {
            name: item.fileName,
            size: item.fileSize,
            uid: item.id,
            fileName: item.fileName,
          };
        });
      });
    };

    const deleteLicenseImg = () => {
      deleteClick.value = true;
      pictureModal.pictureModalTitle = "";
      pictureModal.pictureModalImg = "";
    };

    const closeModal = (params: { isVisible: boolean; continue: boolean }) => {
      modalVisible.value = params.isVisible;
    };

    //关闭页面
    const closePage = () => {
      emit("close-appeal-page-modal");
    };

    //保存
    const saveLicenseDetail = () => {
      delete data["appealFileInfoList"];
      formRef.value.validate().then(() => saveLicenseInfo(data));
    };

    //申诉提交
    const sumbitLicenseDetail = () => {
      delete data["appealFileInfoList"];
      formRef.value.validate().then(() => sumbitLicenseInfo(data));
      emit("close-appeal-page-modal");
    };

    const beforeUserUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "请上传小于5MB的文件";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const licenseNum = fileList.length < 10;
        if (!licenseNum) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "上传文件不能超过10张";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const isJpg = file.type === "image/jpeg";
        const isDocx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        // const isDocx = file.type === 'application/msword';
        const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isDocx && !isXlsx && !isPdf) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content =
            "文件格式错误，请按照JPG DOCX XLSX PDF格式进行上传";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        tempAppealData.value = fileList;
        fileList.forEach((item: any) => (item.status = ""));
        // ssFileList.value.push(file);
        return resolve(file);
      });
    };

    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        //判断上传文件是否大于5MB
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "请上传小于5MB的文件";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const licenseNum = fileList.length === 1;
        if (!licenseNum) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "上传文件不能超过100张";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const isJpg = file.type === "image/jpeg";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isPdf) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "上传文件格式为jpg,pdf";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        tempLicenseData.value = fileList;
        return resolve(true);
      });
    };

    //submitLicenseFile
    const submitLicenseFile = async () => {
      deleteLicenseImg();
      const formData = new FormData();
      tempLicenseData.value.forEach((item: any) => {
        formData.append("file", item);
      });
      const getvinNo: any = data.vinNo;
      const dealerCode: string =
        sessionStorage.getItem("appealDealerCode") || "";
      await uploadLicenseFile(formData, getvinNo, dealerCode);
      getLicenseImg();
    };

    //submitAppealFile
    const submitAppealFile = (file: any) => {
      const appealData = new FormData();
      appealData.append("files", file.file.originFileObj);
      const getvinNo: any = data.vinNo;
      uploadAppealItem(appealData, getvinNo, data.dealerCode).then(
        (res: any) => {
          if (res.code == "0") {
            res.data.forEach((item: any) => {
              const newFile: any = {
                uid: item.id,
                name: item.fileName,
                fileName: item.fileName,
                size: item.fileSize,
                status: "done",
                downloadUrl: item.downloadUrl,
                base64String: item.base64String,
              };
              const namex = ssFileList.value.findIndex((item) => {
                return item.name === newFile.name;
              });
              if (namex === -1) {
                ssFileList.value.push(newFile);
              } else {
                ssFileList.value.splice(namex, 1, newFile);
              }
            });
          } else {
            message.error(res.message);
          }
        }
      );
    };
    //下载文件
    const downloadFile = (item: any) => {
      let URL = {};
      const fileName = item.fileName;
      const fileUrl = item.base64String;
      if (fileName.indexOf(".docx") > -1) {
        URL = base64toBlob(
          fileUrl,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      } else if (fileName.indexOf(".xlsx") > -1) {
        URL = base64toBlob(
          fileUrl,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      } else if (fileName.indexOf(".jpg") > -1) {
        URL = base64toBlob(fileUrl, "image/jpeg");
      } else if (fileName.indexOf(".pdf") > -1) {
        URL = base64toBlob(fileUrl, "application/pdf");
      }
      const downloadElement = document.createElement("a");
      downloadElement.href = window.URL.createObjectURL(URL);
      downloadElement.download = fileName;
      downloadElement.click();
    };

    //删除文件
    const deleteAppealLink = (index: number) => {
      deleteAppealItem(ssFileList.value[index].uid).then((res: any) => {
        if (res.code == "0") {
          ssFileList.value.splice(index, 1);
        } else {
          message.error(res.message);
        }
      });
    };
    //初始化
    const init = () => {
      getLicenseImg();
      getLicenseItem();
    };
    init();

    return {
      ssFileList,
      data,
      appealComment,
      rules,
      pictureModal,
      modalParams,
      formRef,
      deleteLicenseImg,
      deleteClick,
      beforeUserUpload,
      beforeUpload,
      closePage,
      modalVisible,
      modalData,
      closeModal,
      saveLicenseDetail,
      submitLicenseFile,
      sumbitLicenseDetail,
      submitAppealFile,
      computeSize,
      deleteAppealLink,
      computeName,
      downloadFile,
    };
  },
  BeforeUnmount() {
    sessionStorage.clear();
  },
});
