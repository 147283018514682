
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  emits: ["close-checking-visible","close-checking-result-visible"],
  props: {
    detailVisible: {
      default: false,
      type: Boolean,
    },
    btnVisible: {
      default: false,
      type: Boolean,
    },
    checkingParams: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  setup(props, context) {
    const modalVisible = (props as any).detailVisible as boolean;
    const modalBtnVisible = (props as any).btnVisible as boolean;
    const modalData = (props as any).checkingParams as boolean;
    const close = () => {
      context.emit("close-checking-visible");
    };
    const goAppealPage = () => {
      context.emit("close-checking-result-visible")
    };
    return {
      modalVisible,
      modalBtnVisible,
      modalData,
      goAppealPage,
      close,
    };
  },
});
